<template>
    <div>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" :offset="10" :immediate-check="false" finish-text="没有更多了"
                @load="onLoad">
                <div class="flexd">
                    <div class="flex-item ">
                        <div class="backcolor">
                            <span class="iconfont icon-fanhui" @click="getHome">返回</span>
                        </div>
                    </div>
                    <!-- 切换 -->
                    <!-- <div class="flex-items">
                        <div class="box" :class="indexs == index ? 'boxs' : 'box'" v-for="(item, index) in list"
                            :key="index" @click="getChange(index)">
                            {{ item.text }}</div>
                    </div> -->
                </div>
                <!-- 占位符 -->
                <div style="height: 50px;background-color: #fafafa;z-index: 999;">
                </div>
                <!-- 内容 -->
                <div class="contain" v-if="listData.length>0">
                    <div class="contain-box" @click="getDetail(item.id)" v-for="(item, index) in listData" :key="index">
                        <!-- 标题 -->
                        <div class="display margin_top">
                            <div class="left"></div>
                            <div class="text">{{ item.title }}</div>
                        </div>
                        <!-- 时间人数 -->
                        <div class="display time_num">
                            <div class="display">
                                <div class="avater">
                                    <img src="../../assets/img/avatar.png" alt="">
                                </div>
                                <div class="name">{{ item.user_name }}</div>
                            </div>
                            <div class="display">
                                <div class="title">发布时间：</div>
                                <div class="time"> {{ item.create_time }}</div>
                            </div>
                            <div v-if="item.view_status == 1" class="display">
                                <!-- <div class="no"></div> -->
                                <div class="title">已读</div>
                            </div>
                            <div v-else class="display">
                                <div class="no"></div>
                                <div class="title">未读</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 如果没有信息 -->
                <div v-else class="background">
                    <div class="background_img"><img src="../../assets/img/background.png" alt=""></div>
                    <div class="background_text">当前页面空空如也</div>
                </div>
            </van-list>
        </van-pull-refresh>
    </div>
</template>
<script>
import { NoticeIndex } from "../../api/notice/notice";

export default {
    data() {
        return {
            list: [{
                text: '全部'
            }, {
                text: '学校通知'
            }, {
                text: '班级通知'
            }],
            indexs: 0,
            read: false,//true已读 false未读
            showTime: false,//班级通知 false学校通知,
            listDta: [],
            listData: [],
            classes_id: '',
            page: 1,
            isLoading: false,//下拉加载
            loading: false,
            finished: false,
            type: ''

        };
    },
    created() {
      this.getList()
    },
    methods: {
        getChange(e) {
            this.indexs = e
            this.finished = false
            if (e == 0) {
                this.type = ''
                this.listData = this.listDta
                this.getList()

            } else if (e == 1) {
                // 学校通知
                this.type = 1
                this.page = 1
                this.getList()
            } else {
                // 班级通知
                this.page = 1
                this.type = 2
                this.getList()
            }
        },
        // 选择器
        // 详情
        getDetail(id) {
            this.$router.push({ path: "/ParentsDetail", query: { id } });
        },
        getHome() {
            this.$router.push("/student_index");
        },
        getList() {
            NoticeIndex({ type: this.type, classes_id: this.classes_id, page: this.page, limit: '10' }).then((res) => {
                if (this.page == '1') {
                    // 下刷新
                    this.listData = res.data.rows
                    console.log(res)

                } else {
                    // 上拉加载
                    console.log(res)
                    this.loading = false;
                    this.listData = [...this.listData, ...res.data.rows]; // 上一页数据跟新加载的数据做合并
                    if (res.data.rows.length < 1) {
                        this.$toast('暂无更多数据');
                        this.finished = true;
                    }
                }
            })
        },
        // 下拉
        onRefresh() {
            setTimeout(() => {
                this.$toast('刷新成功');
                this.isLoading = false;
                this.page = 1
                this.getList()
            }, 500);
        },
        // 上拉
        onLoad() {
            this.loading = true
            this.page++
            setTimeout(() => {
                this.getList()
            }, 500);
        },
    },
    mounted() {
    },
    beforeDestroy() {
    },
};
</script>
<style lang="less">
.flexd {
    position: fixed;
    background-color: #fafafa;
    width: 100%;
}

.flex-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
}

.backcolor {
    color: #ffffff;
    width: 52px;
    height: 24px;
    border-radius: 12px;
    background-color: #AFAFAF;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-size: 12px;

    }
}

.flex-items {
    display: flex;
    align-items: center;
    padding: 15px 15px;

    .box {
        width: 70px;
        height: 35px;
        background-color: #efefef;
        text-align: center;
        line-height: 35px;
        color: #afafaf;
        margin-right: 15px;
        border-radius: 8px;
        font-size: 12px;
    }

    .boxs {
        width: 70px;
        height: 35px;
        background-color: #6990ff;
        text-align: center;
        line-height: 35px;
        color: #ffffff;
        margin-right: 15px;
        border-radius: 8px;
        font-size: 12px;
    }

    .allClasss {
        color: #afafaf;
        font-size: 12px;
    }

    .title {
        color: #afafaf;
        font-size: 12px;
    }
}

.contain {
    padding: 0 15px;
}

.bottom {
    width: 100%;
    text-align: center;

}

.contain-box {
    width: 100%;
    height: 100px;
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 16px;
}

.display {
    display: flex;
    align-items: center;
}

.margin_top {
    padding-top: 10px;

    .left {
        height: 20px;
        width: 5px;
        background-color: #6990ff;
    }

    .text {
        margin-left: 10px;
        width: 192px;
        height: 22px;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #444444;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
    }
}

.time_num {
    padding: 10px 15px;
    justify-content: space-between;

    .title {
        font-size: 12px;
        color: #8c8c8c;
    }

    .time {
        font-size: 12px;
        margin-top: 1px;
    }
}

.avater {
    width: 25px;
    height: 25px;
    border-radius: 50%;

    img {
        width: 100%;
    }
}

.name {
    margin-left: 10px;
    width: 36px;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
}

.no {
    width: 6px;
    height: 6px;
    background: #F95E5A;
    opacity: 1;
    border-radius: 50%;
    margin-right: 3px;
}

.type {
    width: 60px;
    height: 18px;
    background: #E5EDFA;
    opacity: 1;
    font-size: 10px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #5380FF;
    border-radius: 0px 8px 0 8px;
    line-height: 18px;
    text-align: center;
    margin-left: 285px;

}

.types {
    width: 60px;
    height: 18px;
    background: #FFF9D8;
    opacity: 1;
    font-size: 10px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFA613;
    line-height: 18px;
    text-align: center;
    margin-left: 285px;

}

.background {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .background_img {
        width: 234px;
        height: 234px;

        img {
            width: 234px;
            height: 234px;
        }
    }

    .background_text {
        width: 102px;
        height: 17px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
}</style>